// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-events-better-code-reviews-index-tsx": () => import("./../src/pages/events/better-code-reviews/index.tsx" /* webpackChunkName: "component---src-pages-events-better-code-reviews-index-tsx" */),
  "component---src-pages-events-embracing-tprc-move-fast-and-break-nothing-with-end-to-end-typesafe-apis-index-tsx": () => import("./../src/pages/events/embracing-tprc-move-fast-and-break-nothing-with-end-to-end-typesafe-apis/index.tsx" /* webpackChunkName: "component---src-pages-events-embracing-tprc-move-fast-and-break-nothing-with-end-to-end-typesafe-apis-index-tsx" */),
  "component---src-pages-events-going-3-d-with-react-index-tsx": () => import("./../src/pages/events/going-3d-with-react/index.tsx" /* webpackChunkName: "component---src-pages-events-going-3-d-with-react-index-tsx" */),
  "component---src-pages-events-index-tsx": () => import("./../src/pages/events/index.tsx" /* webpackChunkName: "component---src-pages-events-index-tsx" */),
  "component---src-pages-events-secrets-of-building-robust-ui-components-index-tsx": () => import("./../src/pages/events/secrets-of-building-robust-ui-components/index.tsx" /* webpackChunkName: "component---src-pages-events-secrets-of-building-robust-ui-components-index-tsx" */),
  "component---src-pages-events-typescript-patterns-for-better-react-components-index-tsx": () => import("./../src/pages/events/typescript-patterns-for-better-react-components/index.tsx" /* webpackChunkName: "component---src-pages-events-typescript-patterns-for-better-react-components-index-tsx" */),
  "component---src-pages-events-working-with-data-in-react-index-tsx": () => import("./../src/pages/events/working-with-data-in-react/index.tsx" /* webpackChunkName: "component---src-pages-events-working-with-data-in-react-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

